<template>
  <section>
    <div class="level-item is-hero-content-item">
      <div>
        <div class="columns">
          <div class="column">
            <b-field label="Registre" v-if="this.registries.length > 1">
              <b-select
                placeholder="Selecteaza un registru"
                :value="currentRegistry"
                :loading="!registries.length"
                @input="onRegisterChanged"
              >
                <option v-for="opt in registries" :value="opt" :key="opt.id">
                  {{ opt.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rawObject } from "@/helpers/utils";

export default {
  methods: {
    onRegisterChanged(val) {
      this.$emit("handleChangedRegister", rawObject(val.id));
    },
  },
  props: {
    registries: {
      type: Array,
      default: null,
    },
    currentRegistry: {
      type: Object,
      default: () => {},
    },
  },
  name: "RegistriesSelector",
};
</script>
