<template>
  <section class="hero is-hero-bar is-main-hero">
    <title-bar :title-stack="titleStack" />
    <div class="hero-body">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <p class="subtitle is-5">
              <registries-selector
                :registries="registries"
                :currentRegistry="currentRegistry"
                @handleChangedRegister="handleChangedRegister"
              />
            </p>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <p class="level-item">
            <reserved-numbers v-if="registryId != 0" :registryId="registryId" />
          </p>
        </div>
      </nav>
      <nav class="level">
        <div
          class="level-left"
          v-if="defaultChart.chartData && registryId != 0"
        >
          <div class="level-item is-hero-avatar-item">
            <div class="image is-user-avatar">
              <div class="chart-area">
                <pie-chart
                  ref="bigChart"
                  chart-id="big-line-chart"
                  :chart-data="defaultChart.chartData"
                  :extra-options="defaultChart.extraOptions"
                  :height="250"
                  :width="250"
                >
                </pie-chart>
              </div>
            </div>
          </div>
          <div class="level-item is-hero-content-item">
            <div>
              <h1 class="title is-spaced">{{ this.getGreetingMessage() }}</h1>
              <span style="white-space: pre-line" v-if="registryId != 0">
                Exista <b> {{ this.numberOfActiveEntries }} </b> documente in
                lucru. Dintre acestea:
                <ul>
                  <li>
                    <b> {{ this.numberOfEntriesDeadlinePassed }} </b> documente
                    au data limita de raspuns depasita si necesita raspuns.
                  </li>
                  <li>
                    <b> {{ this.numberOfEntriesCanBeClosed }} </b> documente
                    contin deja un raspuns si pot fi salvate cu statusul
                    "Rezolvat".
                  </li>
                  <li>
                    <b> {{ this.numberOfEntriesWithoutDeadline }} </b> documente
                    nu au data limita de raspuns.
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div class="columns">
        <div class="column">
          <div class="buttons">
            <b-button
              icon-left="file-plus"
              class="button is-primary"
              @click="addRegistryEntry"
            >
              Inregistrare noua
            </b-button>
            <b-button
              icon-left="refresh"
              class="button is-success"
              @click="reloadData"
              >Reincarcare date
            </b-button>
            <b-button
              :class="isFilterSelected ? 'button is-warning' : 'button is-info'"
              @click="isFiltersOpened = !isFiltersOpened"
              :icon-left="
                !isFiltersOpened ? 'chevron-double-down' : 'chevron-double-up'
              "
            >
              <!-- <b-icon :icon="custom - size" ="default" /> -->
              <p v-if="isFilterSelected">
                Filtre (Exista filtre aplicate) -
                {{ this.entries.length }} inregistrari afisate
              </p>
              <p v-else>
                Filtre (Nu exista filtre aplicate) -
                {{ this.entries.length }} inregistrari afisate
              </p>
            </b-button>
            <b-button
              icon-left="printer"
              class="button is-primary"
              v-if="this.entries"
              @click="print()"
            >
              Imprimare
            </b-button>
            <download-excel
              :data="this.entries"
              :fields="json_fields"
              name="Inregistrari.xls"
              worksheet="Inregistrari"
            >
              <b-button
                icon-left="file-excel"
                class="button is-primary"
                v-if="this.entries"
              >
                Export .xls (format Excel)
              </b-button>
            </download-excel>
          </div>
        </div>
      </div>
    </div>
    <RegistriesFilter
      class="mt-5"
      :propIsOpened="isFiltersOpened"
      :propIsSelected="isFilterSelected"
      @update-opened-value="updateFilterOpenedStatus"
      @update-selected-value="updateFilterSelectedStatus"
      @filterRegistryEntries="filterRegistryEntries"
    ></RegistriesFilter>
    <card-component
      class="has-table has-mobile-sort-spaced"
      title="Inregistrari"
      icon="file-multiple"
    >
      <registry-entries-table
        @handleDeleteRegistryEntry="handleDeleteRegistryEntry"
        :entries="entries"
        :maxEntry="maxEntry"
      />
    </card-component>
  </section>
</template>

<script>
import * as chartConfig from "@/components/Charts/chart.config";
import RegistryEntries from "@/services/registryEntries.service";
import RegistriesService from "@/services/registry.service";
import RegistryEntriesTable from "@/components/registry/registryEntries-table";
import RegistriesSelector from "@/components/registry/registries-selector";
import RegistriesFilter from "@/components/registry/registries-filter";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
//import HeroBar from "@/components/HeroBar";
import ReservedNumbers from "../../components/registry/reservedNumbers.vue";
import PieChart from "@/components/Charts/PieChart";
import * as Printjs from "print-js";
import moment from "moment";

const _ = require("lodash");

export default {
  name: "RegistryEntries",
  components: {
    //HeroBar,
    TitleBar,
    PieChart,
    CardComponent,
    RegistryEntriesTable,
    RegistriesSelector,
    ReservedNumbers,
    RegistriesFilter,
  },
  data() {
    return {
      json_fields: {
        "Numar inregistrare intern": "internalRegistrationNumber",
        "Data inreg intern": {
          callback: (value) => {
            return `${moment(value.internalRegistrationDate).format(
              "DD/MM/YYYY"
            )}`;
          },
        },
        Continut: "description",
        Emitent: "issuer",
        Departament: "department",
        "Nr. Inreg. extern": "externalRegistrationNumber",
        "Data limita raspuns": {
          callback: (value) => {
            return `${moment(value.deadline).format("DD/MM/YYYY")}`;
          },
        },
        "Continut raspuns": "responseDescription",
        "Data raspuns": {
          callback: (value) => {
            return `${moment(value.responseDate).format("DD/MM/YYYY")}`;
          },
        },
        "Tip document": "type",
      },
      defaultChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsMain,
      },
      registryId: 0,
      registries: [],
      entries: [],
      isFiltersOpened: false,
      isFilterSelected: false,
      reservedRegistrationNumbers: [],
      maxEntry: 0,
      numberOfActiveEntries: 0,
      numberOfEntriesDeadlinePassed: 0,
      numberOfEntriesCanBeClosed: 0,
      numberOfEntriesWithoutDeadline: 0,
      numberOfEntriesDeadlineNotPassed: 0,
      registryEntriesFilters: {
        dateInterval: null,
        fromDate: null,
        toDate: null,
        status: null,
        deadlineInterval: null,
        response: null,
        registryId: 0,
      },
    };
  },
  mounted() {},
  created() {
    this.setupRegistries().then(() => {
      this.getRegistryEntries();
      this.getRegistryStats(this.registryId);
    });
  },
  methods: {
    exportData(data) {},
    print() {
      printJS({
        printable: this.entries,
        properties: [
          {
            field: "internalRegistrationNumber",
            displayName: "Nr. Inreg. intern",
          },
          { field: "department", displayName: "Department" },
          { field: "description", displayName: "Continut" },
        ],
        type: "json",
        documentTitle: "Inregistrari",
        showModal: true,
        modalMessage: "Generare raport",
        style: "td { text-align: center; }",
      });
    },
    getGreetingMessage() {
      var d = new Date();
      var time = d.getHours();
      if (time < 12) {
        return "Buna dimineata!";
      }
      if (time > 12) {
        return "Buna ziua!";
      }
    },
    fillChartData() {
      this.defaultChart.chartData = {
        datasets: [
          {
            label: "Statistici documente",
            data: [
              this.numberOfEntriesDeadlinePassed,
              this.numberOfEntriesCanBeClosed,
              this.numberOfEntriesWithoutDeadline,
            ],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 235, 111)",
              "rgb(255, 205, 86)",
            ],
            hoverOffset: 4,
          },
        ],
        labels: [
          "Necesita raspuns",
          "Contin deja un raspuns",
          "Nu au data limita de raspuns",
        ],
      };
    },
    reloadData() {
      this.getRegistryEntries();
    },
    async handleChangedRegister(registryId) {
      this.registryId = registryId;
      var retrievedObject = JSON.parse(localStorage.getItem("filters"));
      retrievedObject.registryId = this.registryId;
      localStorage.setItem("filters", JSON.stringify(retrievedObject));
      this.getRegistryEntries(retrievedObject);
      this.getRegistryStats(this.registryId);
    },
    async setupRegistries() {
      const { getRegistries } = RegistriesService;
      await getRegistries().then((response) => {
        const { data } = response;
        this.registries = data;
        if (localStorage["filters"] == undefined) {
          let permission = this.$store.state.permissions[0];
          let obj = this.registries.find((o, i) => {
            if (o.permission.includes(permission[0])) {
              return this.registries[i];
            }
          });
          this.registryId = obj.id;
          this.registryEntriesFilters.registryId = this.registryId;
          localStorage.setItem(
            "filters",
            JSON.stringify(this.registryEntriesFilters)
          );
        } else {
          let storageFilters = JSON.parse(localStorage.getItem("filters"));
          this.registryId = storageFilters.registryId;
        }
      });
    },
    async getRegistryEntries() {
      var filters = JSON.parse(localStorage.getItem("filters"));
      const { getRegistryEntries } = RegistriesService;
      await getRegistryEntries(filters).then((response) => {
        const { data } = response;
        this.entries = data;
        let maxEntry = this.entries[0].internalRegistrationNumber;
        this.maxEntry = maxEntry;
      });
    },
    async getRegistryStats(registryId) {
      const { getRegistryStats } = RegistriesService;
      await getRegistryStats(registryId).then((response) => {
        const { data } = response;
        this.numberOfActiveEntries = data.numberOfActiveEntries;
        this.numberOfEntriesDeadlinePassed = data.numberOfEntriesDeadlinePassed;
        this.numberOfEntriesCanBeClosed = data.numberOfEntriesCanBeClosed;
        this.numberOfEntriesWithoutDeadline =
          data.numberOfEntriesWithoutDeadline;
        this.numberOfEntriesDeadlineNotPassed =
          data.numberOfEntriesDeadlineNotPassed;
        this.fillChartData();
      });
    },
    async addRegistryEntry() {
      this.$router.push({
        name: "registryEntry.add",
        params: { registryId: this.registryId },
      });
    },
    async filterRegistryEntries(filters) {
      var retrievedObject = JSON.parse(localStorage.getItem("filters"));
      retrievedObject.dateInterval = filters.dateInterval;
      retrievedObject.status = filters.status;
      retrievedObject.fromDate = filters.fromDate;
      retrievedObject.toDate = filters.toDate;
      retrievedObject.deadlineInterval = filters.deadlineInterval;
      retrievedObject.response = filters.response;
      localStorage.setItem("filters", JSON.stringify(retrievedObject));

      this.getRegistryEntries();
    },
    async handleDeleteRegistryEntry(id) {
      const { deleteRegistryEntry } = RegistryEntries;
      deleteRegistryEntry(id).then((response) => {
        const { data, status } = response;
        if (status == "200") {
          const array = this.entries;
          const index = this.entries.findIndex((prop) => prop.id === id);
          this.entries.splice(index, 1);
          let maxEntry = this.entries[0].internalRegistrationNumber;
          this.maxEntry = maxEntry;
          this.$buefy.snackbar.open({
            message: "Inregistrea a fost stearsa.",
            queue: false,
          });
        } else {
          this.$buefy.snackbar.open({
            message: "Inregistrea nu a putut fi stearsa.",
            queue: false,
          });
        }
      });
    },
    updateFilterOpenedStatus(val) {
      this.isFiltersOpened = val;
    },
    updateFilterSelectedStatus(val) {
      this.isFilterSelected = val;
    },
  },
  computed: {
    titleStack() {
      return ["Registratura"];
    },
    heroRouterLinkTo() {
      return { name: "/announcements/add" };
    },
    currentRegistry() {
      return this.registries.find((item) => item.id === this.registryId);
    },
  },
};
</script>
