<template>
  <section>
    <b-button
      v-if="hasRegistraturaAccess()"
      class="button is-primary"
      @click="isCardModalActive = true"
    >
      Rezerva numere
    </b-button>
    <b-modal :active.sync="isCardModalActive" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Rezervare numere</p>
        </header>
        <section class="modal-card-body py-3">
          <div class="mb-2">
            <ValidationObserver ref="form">
              <ValidationProvider
                name="Numar rezervare"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field
                  :type="errors[0] ? 'is-danger' : ''"
                  label="Cate numere doriti sa rezervati:"
                  :message="errors[0]"
                >
                  <b-numberinput
                    min="1"
                    max="100"
                    v-model="numberOfReservedNumbers"
                    @input="onNumberOfReservedNumbersChanged"
                  >
                  </b-numberinput>
                </b-field>
              </ValidationProvider>
            </ValidationObserver>
            <div class="container">
              <p>Numerele care vor fi rezervate:</p>
              <span v-for="(name, index) in newReservedNumbers" :key="index">
                {{ nameWithComma(index) }}
              </span>
            </div>
          </div>
          <b-collapse :open="false" aria-id="contentIdForA11y1">
            <template #trigger>
              <b-button
                label="Arata numerele deja rezervate"
                type="is-primary"
                aria-controls="contentIdForA11y1"
                @click="showReservedNumbers()"
                class="mb-2"
              />
            </template>
            <b-table
              :checked-rows.sync="checkedRows"
              :loading="isLoading"
              :paginated="paginated"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :data="reservedRegistrationNumbers"
            >
              <b-table-column
                label="Numar inregistrare"
                field="number"
                sortable
                v-slot="props"
                cell-class="has-text-left"
              >
                {{ props.row.number }}
              </b-table-column>
              <b-table-column
                label="Data inregistrarii"
                field="registrationDate"
                sortable
                v-slot="props"
                cell-class="has-text-right"
                header-class="is-flex-tablet is-justify-content-end has-text-right"
              >
                {{ props.row.registrationDate | formatDate }}
              </b-table-column>
              <section class="section" slot="empty">
                <div class="content has-text-grey has-text-centered">
                  <template v-if="isLoading">
                    <p>
                      <b-icon icon="dots-horizontal" size="is-large" />
                    </p>
                    <p>Se incarca datele...</p>
                  </template>
                  <template v-else>
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large" />
                    </p>
                    <p>Nu exista inregistrari</p>
                  </template>
                </div>
              </section>
            </b-table>
          </b-collapse>
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-primary" @click="handleSaveReservedNumbers"
            >Salvare</b-button
          >
          <button class="button" type="button" @click="cancel()">
            Anulare
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import RegistriesService from "@/services/registry.service";
import { accessMixin } from "../../mixins/accessMixin";

export default {
  props: {
    registryId: {
      type: Number,
      default: 0,
    },
  },
  mixins: [accessMixin],
  created() {
    this.newReservedNumbers = [];
    this.numberOfReservedNumbers = 0;

    this.getNextRegNumber(this.registryId).then((response) => {
      this.nextRegistrationNumber = response.data;
    });
  },
  methods: {
    showReservedNumbers() {
      const { getReservedNumbers } = RegistriesService;
      getReservedNumbers(this.registryId).then((response) => {
        this.reservedRegistrationNumbers = response.data;
      });
    },
    async getNextRegNumber() {
      const { getNextRegistrationNumber } = RegistriesService;
      const nextRegistrationNumberresponse = await getNextRegistrationNumber(
        this.registryId
      );
      return nextRegistrationNumberresponse;
    },
    nameWithComma(index) {
      if (index !== this.newReservedNumbers.length - 1) {
        return `${this.newReservedNumbers[index].number},`;
      } else {
        return this.newReservedNumbers[index].number;
      }
    },
    calculateReservedNumbers(numberOfReservedNumbers) {
      this.newReservedNumbers = [];
      for (let i = 0; i < numberOfReservedNumbers; i++) {
        this.newReservedNumbers.push({
          number: this.nextRegistrationNumber,
          registrationDate: new Date(),
          registryId: this.registryId,
        });
        this.nextRegistrationNumber++;
      }
    },
    handleSaveReservedNumbers() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        const { addNewReservedNumbers } = RegistriesService;
        addNewReservedNumbers(this.newReservedNumbers).then(() => {
          this.reserving = false;
          this.isCardModalActive = false;
          this.newReservedNumbers = [];
          this.numberOfReservedNumbers = 0;
        });
      });
    },
    cancel() {
      (this.isCardModalActive = false), (this.numberOfReservedNumbers = 0);
      this.newReservedNumbers = [];
    },
    onNumberOfReservedNumbersChanged() {
      this.reserving = true;

      this.getNextRegNumber(this.registryId).then((response) => {
        this.nextRegistrationNumber = response.data;
        this.calculateReservedNumbers(this.numberOfReservedNumbers);
      });
    },
  },
  data() {
    return {
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: [],
      isCardModalActive: false,
      numberOfReservedNumbers: 0,
      nextRegistrationNumber: 0,
      newReservedNumbers: [
        {
          number: null,
          registrationDate: null,
          registryId: null,
        },
      ],
      reserving: false,
      reservedRegistrationNumbers: [],
      columns: [
        {
          field: "number",
          label: "Numar de inregistrare",
          numeric: true,
        },
        {
          field: "registrationDate",
          label: "Data",
          numeric: false,
        },
      ],
    };
  },
  name: "ReservedNumbers",
};
</script>

<style scoped>
::v-deep .table-mobile-sort {
  padding: 0 !important;
}
</style>
