<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="entries"
    >
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-right">
          <router-link
            :to="{
              name: 'registryEntry.edit',

              params: { registryId: props.row.registryId, id: props.row.id },
            }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>

          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
            v-if="canDelete(props.row.internalRegistrationNumber)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </button>
        </div>
      </b-table-column>
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-if="column.date" v-slot="props">
            {{ props.row[column.field] | formatDate }}
          </template>
          <template v-else v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        field="status"
        label="Status"
        sortable
        centered
        v-slot="props"
      >
        <span :class="getComputedStyle(props.row)">
          {{ props.row.status }}
        </span>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Incarcare data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nici o inregistrare&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from "@/components/ModalBox";
import { openBlankPage } from "@/helpers/utils";
import { isNilOrEmpty } from "@/helpers/utils";
import moment from "moment";
import { accessMixin } from "../../mixins/accessMixin";

export default {
  name: "RegistryEntriesTable",
  components: { ModalBox },
  mixins: [accessMixin],
  props: {
    entries: {
      type: Array,
      default: null,
    },
    maxEntry: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      perPage: 100,
      columns: [
        {
          field: "internalRegistrationNumber",
          label: "Nr. Inreg. intern",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "internalRegistrationDate",
          label: "Data Inreg. intern",
          sortable: true,
          searchable: false,
          centered: true,
          date: true,
        },
        {
          field: "description",
          label: "Continut",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "issuer",
          label: "Emitent",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "department",
          label: "Departament",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "externalRegistrationNumber",
          label: "Nr. Inreg. extern",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "deadline",
          label: "Data limita raspuns",
          sortable: true,
          searchable: false,
          centered: true,
          date: true,
        },
        {
          field: "responseDescription",
          label: "Continut raspuns",
          numeric: false,
          sortable: false,
          searchable: true,
          centered: true,
        },
        {
          field: "responseDate",
          label: "Data raspuns",
          numeric: false,
          date: true,
          sortable: false,
          searchable: false,
          centered: true,
        },
        {
          field: "type",
          label: "Tip document",
          numeric: false,
          sortable: true,
          searchable: false,
          centered: true,
        },
      ],
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return (
          this.trashObject.internalRegistrationNumber +
          " din data " +
          moment(this.trashObject.registrationDate).format("DD/MM/YYYY")
        );
      }

      return null;
    },
  },
  mounted() {
    this.isLoading = false;
    if (this.entries && this.entries > 0) {
      if (this.entries.length > this.perPage) {
        this.paginated = true;
      }
    }
  },
  methods: {
    canDelete(currentId) {
      return (
        (currentId === this.maxEntry && this.hasRegistraturaAccess()) ||
        (currentId === this.maxEntry && this.hasAdminToRegistry())
      );
    },
    getComputedStyle(registryEntry) {
      if (registryEntry.status === "Rezolvat") return "tag is-success";
      if (registryEntry.status === "În lucru") return "tag is-warning";
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    downloadFile(file) {
      if (!isNilOrEmpty(file)) {
        openBlankPage(file);
      }
    },
    trashConfirm() {
      this.isModalActive = false;
      this.$emit("handleDeleteRegistryEntry", this.trashObject.id);
    },
    trashCancel() {
      this.isModalActive = false;
    },
  },
};
</script>
