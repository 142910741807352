<template>
  <b-collapse animation="slide" v-model="isOpened">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title">
          <b-icon icon="filter-minus-outline" custom-size="default" />
          <span>Filtre</span>
        </p>
      </div>
      <div class="card-content">
        <ValidationObserver ref="form">
          <div class="columns">
            <div class="column is-4">
              <div class="columns">
                <div class="column is-narrow">
                  <ValidationProvider
                    name="Filtrare in functie de data"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0]"
                      label="Filtrare in functie de data inregistrarii"
                    >
                      <div class="columns">
                        <b-radio
                          v-model="filters.dateInterval"
                          name="date"
                          native-value="today"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Astazi
                        </b-radio>
                        <b-radio
                          v-model="filters.dateInterval"
                          name="date"
                          native-value="week"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Ultimele 7 zile
                        </b-radio>
                        <b-radio
                          v-model="filters.dateInterval"
                          name="date"
                          native-value="month"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Utimele 30 de zile
                        </b-radio>
                        <b-radio
                          v-model="filters.dateInterval"
                          name="date"
                          native-value="custom"
                          class="column is-12-mobile is-narrow-tablet"
                        >
                          Un interval (De la/Pana la)
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div v-show="filters.dateInterval === 'custom'" class="columns">
                <div class="column is-4">
                  <ValidationProvider name="From" v-slot="{ errors }">
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="De la"
                      :message="errors[0]"
                    >
                      <b-datepicker
                        :date-parser="parser"
                        v-model="filters.fromDate"
                        locale="ro"
                        @input="dateFromSelected()"
                      >
                      </b-datepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-4">
                  <ValidationProvider name="To" v-slot="{ errors }">
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Pana la"
                      :message="errors[0]"
                    >
                      <b-datepicker
                        :date-parser="parser"
                        v-model="filters.toDate"
                        locale="ro"
                        @input="dateToSelected()"
                      >
                      </b-datepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="columns">
                <div class="column is-narrow">
                  <ValidationProvider
                    name="Filtrare in functie de status"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0]"
                      label="Filtrare in functie de status"
                    >
                      <div class="columns">
                        <b-radio
                          v-model="filters.status"
                          name="status"
                          native-value="Rezolvat"
                          class="column is-narrow"
                          @input="applyFilters()"
                        >
                          Rezolvat
                        </b-radio>
                        <b-radio
                          v-model="filters.status"
                          name="status"
                          native-value="În lucru"
                          class="column is-narrow"
                          @input="applyFilters()"
                        >
                          În lucru
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column is-3"></div>
            <div class="column is-7">
              <div class="columns">
                <div class="column">
                  <ValidationProvider
                    name="Filtrare in functie de data"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0]"
                      label="Filtrare in functie de data limita"
                    >
                      <div class="columns">
                        <b-radio
                          v-model="filters.deadlineInterval"
                          native-value="noDeadline"
                          name="deadline"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Fara
                        </b-radio>
                        <b-radio
                          v-model="filters.deadlineInterval"
                          name="deadline"
                          native-value="deadlinePassed"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Depasita
                        </b-radio>
                        <b-radio
                          v-model="filters.deadlineInterval"
                          name="deadline"
                          native-value="deadlineToday"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Astazi
                        </b-radio>
                        <b-radio
                          v-model="filters.deadlineInterval"
                          name="deadline"
                          native-value="deadlineTomorrow"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Maine
                        </b-radio>
                        <b-radio
                          v-model="filters.deadlineInterval"
                          name="deadline"
                          native-value="deadlineTwoDays"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Peste 2 zile
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <ValidationProvider
                    name="Filtrare in functie de data"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0]"
                      label="Filtrare in functie de raspuns"
                    >
                      <div class="columns">
                        <b-radio
                          v-model="filters.response"
                          name="response"
                          native-value="noResponse"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Fara raspuns
                        </b-radio>
                        <b-radio
                          v-model="filters.response"
                          name="response"
                          native-value="withResponse"
                          class="column is-12-mobile is-narrow-tablet"
                          @input="applyFilters()"
                        >
                          Cu raspuns
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <div class="columns">
          <div class="column">
            <b-field grouped>
              <div class="control">
                <b-button type="is-danger is-outlined" @click="cancelFilters()"
                  >Anulare filtre</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-warning is-outlined" @click="resetFilters()"
                  >Resetare filtre</b-button
                >
              </div>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: "RegistriesFilter",
  props: {
    propIsOpened: Boolean,
    propIsSelected: Boolean,
  },
  data() {
    return {
      filters: {
        dateInterval: null,
        status: null,
        fromDate: null,
        toDate: null,
        deadlineInterval: null,
        response: null,
      },
    };
  },
  created() {
    if (localStorage["filters"] != undefined) {
      let storageLocal = JSON.parse(localStorage.getItem("filters"));
      this.filters = storageLocal;
    }
    if (
      this.filters.dateInterval == null &&
      this.filters.fromDate == null &&
      this.filters.status == null &&
      this.filters.toDate == null &&
      this.filters.response == null &&
      this.filters.deadlineInterval == null
    ) {
      this.isSelected = false;
    } else {
      this.isSelected = true;
    }
  },
  computed: {
    isOpened: {
      get() {
        return this.propIsOpened;
      },
      set(val) {
        this.$emit("update-opened-value", val);
      },
    },
    isSelected: {
      get() {
        return this.propIsSelected;
      },
      set(val) {
        this.$emit("update-selected-value", val);
      },
    },
  },
  methods: {
    parser(d) {
      return new Date(Date.parse(d));
    },
    dateFromSelected() {
      this.applyFilters();
    },
    dateToSelected() {
      this.applyFilters();
    },
    resetFilters() {
      this.isSelected = false;
      this.filters.dateInterval = null;
      this.filters.status = null;
      this.filters.custom = null;
      this.filters.fromDate = null;
      this.filters.toDate = null;
      this.filters.deadlineInterval = null;
      this.filters.response = null;
      this.$emit("filterRegistryEntries", this.filters);
    },
    cancelFilters() {
      this.isOpened = false;
      this.isSelected = false;
      this.filters.dateInterval = null;
      this.filters.status = null;
      this.filters.custom = null;
      this.filters.fromDate = null;
      this.filters.toDate = null;
      this.filters.deadlineInterval = null;
      this.filters.response = null;
      this.$emit("filterRegistryEntries", this.filters);
    },
    applyFilters() {
      this.isSelected = true;
      if (this.filters.fromDate) {
        this.filters.fromDate = new Date(
          this.filters.fromDate.getTime() -
            this.filters.fromDate.getTimezoneOffset() * 60000
        ).toJSON();
      }
      if (this.filters.toDate) {
        this.filters.toDate = new Date(
          this.filters.toDate.getTime() -
            this.filters.toDate.getTimezoneOffset() * 60000
        ).toJSON();
      }

      this.$emit("filterRegistryEntries", this.filters);
    },
  },
};
</script>
